import Link from 'next/link';
import {Product, ProductSearchItem} from '@/types/objects/product';
import {Inspiration} from '@/types/objects/inspiration';
import Image from '@/app/components/cms/content/PimcoreImage/PimcoreImage';
import ArrowRight from '@/images/icons/arrow-right.svg';
import {slugify} from '@/util/helper/string';
import {NewsEntry} from '@/types/objects/news';

interface ProductItemProps {
    data: Product | Inspiration | NewsEntry | ProductSearchItem;
}

export default function ProductItem({data}: ProductItemProps) {
    return (
        <Link href={slugify(data.detailUrl || '')}
              role="listitem"
              className="relative flex h-full rounded-lg group u-border-gradient-portrait">

            <div className="rounded-lg absolute inset-0 z-0 origin-left scale-x-0 bg-gradient-to-r to-transparent opacity-0 transition-all duration-300 from-grey-150 group-hover:scale-x-100 group-hover:opacity-100"></div>
            <div className="z-10 flex flex-col items-start gap-6 p-6 w-full">
                <div className="aspect-2/1 w-full overflow-hidden rounded">
                    {!!data.teaserImage ? (
                        <Image
                            image={data.teaserImage}
                            className="h-full w-full scale-105 object-cover object-center transition-transform ease-out group-hover:scale-100"
                        />
                    ) : <></>}
                </div>


                <div className="grow">
                    <span className="mb-0 text-blue-200 h5 line-clamp-2">
                        {data.title}
                    </span>
                    {data.teaserText ? (
                        <span className="mt-2 text-grey-500 line-clamp-2" dangerouslySetInnerHTML={{ __html: data.teaserText }}></span>
                    ) : <></>}
                </div>

                <div
                    className="h-12 w-12 shrink-0 rounded-full transition-colors u-center bg-grey-150 text-primary-text group-hover:bg-grey-175">
                    <ArrowRight className="text-primary-text w-6 h-auto"/>
                </div>
            </div>
        </Link>
    )
}