import React, {useEffect} from 'react';
import {createPortal} from 'react-dom';
import {motion, AnimatePresence} from 'framer-motion';
import CloseIcon from '@/images/icons/close.svg';

interface LightboxProps {
    children: React.ReactNode,
    open: boolean,
    setOpen: (open: boolean) => void
}

export default function Lightbox({children, open, setOpen}: LightboxProps) {
    useEffect(() => {
        window.document.documentElement.classList.toggle('overflow-y-hidden', open);
    }, [open]);

    return createPortal(
        <div className={`text-white caption`}>
            <AnimatePresence>
                {open && (
                    <motion.div initial={{opacity: 0, visibility: 'hidden'}}
                                animate={{opacity: 1, visibility: 'visible'}}
                                exit={{opacity: 0}}
                                transition={{
                                    type: 'tween',
                                }}
                                className={`fixed inset-0 w-full h-full bg-[linear-gradient(270deg,rgba(26,107,148,0.88)_0.33%,rgba(12,36,77,0.88)_99.7%)] z-[60]`}>
                        <button onClick={() => setOpen(false)}
                                className="absolute right-5 top-5 p-3 bg-white bg-opacity-5 rounded-full">
                            <CloseIcon className="w-6 h-6 text-white"/>
                        </button>
                        {children}
                    </motion.div>
                )}
            </AnimatePresence>
        </div>,
        window.document.body
    )
}
