/**
 * Transforms a string to kebap-case.
 * @param {string} string
 * @return string
 */
export const slugify = (string: string) => {
    return string.replace(/\s+|%20/g, '-').toLowerCase();
};

export const escapeRegExp = (text: string) => {
    return text.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
}

export const highlightKeywords = (text: string, keywords: string[], highlightClassName: string) => {
    const pattern = keywords.map(escapeRegExp).join('|');
    const regex = new RegExp(`\\b(${pattern})\\b`, 'gi');
    return text.replace(regex, `<span class="${highlightClassName}">$1</span>`);
}
