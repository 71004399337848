'use client';

import { useSwiper } from 'swiper/react';
import IconChevronLeft from '@/images/icons/chevron-left.svg';
import IconChevronRight from '@/images/icons/chevron-right.svg';

interface SliderButtonsProps {
    hasDescriptions?: boolean;
    withLightbox?: boolean;
}

export default function SliderButtons({ hasDescriptions = false, withLightbox = false }: SliderButtonsProps) {
    const swiper = useSwiper();
    const styleClass = 'z-10 u-center bg-white hover:bg-primary transition-colors group rounded-full w-8 h-8';

    return (
        <div className={`aspect-[16/9] w-full !h-full absolute inset-0 items-center justify-between px-6 ${hasDescriptions ? 'bottom-7' : ''} ${withLightbox ? 'hidden sm:flex' : 'flex'}`}>
            <button className={styleClass} onClick={() => swiper.slidePrev()}>
                <IconChevronLeft className="h-6 transition-colors text-primary-text group-hover:text-white" />
            </button>
            <button className={styleClass} onClick={() => swiper.slideNext()}>
                <IconChevronRight className="h-6 transition-colors text-primary-text group-hover:text-white" />
            </button>
        </div>
    )
}