'use client';

import {useAppSelector} from '@/redux/hooks';

interface ITranslations {
    tc: (key: string) => string;
    locale: string;
}

export default function useTranslations(): ITranslations {
    const {translations, locale} = useAppSelector(state => state.translations);
    const tc = (key: string): string => {
        return translations ? (translations[key] || key) : key;
    }

    return {
        tc,
        locale
    }
}
